.PopupContainer
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0,
  left: 0,
  background-color: rgba(0,0,0,0.65)
.PopupWrapper
  width: 200px
  min-height: 150px
  background-color: #ffffff
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  padding: 15px
  border-radius: 10px