.DataFieldContainer
  width: 500px !important
  max-width: 500px !important
  overflow: hidden !important
  overflow-wrap: break-word !important
.DataFieldContainerSmall
  width: 200px !important
  max-width: 200px !important
  overflow: hidden !important
  overflow-wrap: break-word !important
